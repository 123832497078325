import React from "react";
import Popup from "reactjs-popup";

import { Button } from "../index";

const PopupModal = ({ isOpen, title, onCancel, onConfirm }) => {
  return (
    <Popup open={isOpen} onClose={onCancel}>
      <h3 className={"modal_title"}>{title}</h3>
      <div className="modal_actions">
        <div>
          <Button onClick={onCancel} variant="outlined" small>
            Cancel
          </Button>
        </div>
        <div>
          <Button onClick={onConfirm} small>
            Delete
          </Button>
        </div>
      </div>
    </Popup>
  );
};

export default PopupModal;
