import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import { getAdmins, deleteAdmin, createAdmin } from "../../APIs/Admins";

const initialState = {
  admins: [],
  fetchAdminsStatus: "idle",
  removeAdminStatus: "idle",
  addAdminStatus: "idle",
};

export const fetchAdmins = createAsyncThunk("admins/fetchAdmins", async () => {
  const admins = await getAdmins();

  return admins;
});

export const removeAdmin = createAsyncThunk(
  "admins/deleteAdmin",
  async (adminId) => {
    await deleteAdmin(adminId);

    return adminId;
  }
);

export const addAdmin = createAsyncThunk("admins/addAdmin", async (admin) => {
  await createAdmin(admin);

  return true;
});

export const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build.addCase(fetchAdmins.pending, (state) => {
      state.fetchAdminsStatus = "pending";
    });
    build.addCase(fetchAdmins.fulfilled, (state, action) => {
      state.fetchAdminsStatus = "fulfilled";
      state.admins = action.payload;
    });
    build.addCase(fetchAdmins.rejected, (state) => {
      state.fetchAdminsStatus = "rejected";
    });
    build.addCase(removeAdmin.pending, (state) => {
      state.removeAdminStatus = "pending";
    });
    build.addCase(removeAdmin.fulfilled, (state, action) => {
      state.removeAdminStatus = "fulfilled";
      state.admins = state.admins.filter(
        (admin) => admin._id !== action.payload
      );
    });
    build.addCase(removeAdmin.rejected, (state) => {
      state.removeAdminStatus = "rejected";
    });
    build.addCase(addAdmin.pending, (state) => {
      state.addAdminStatus = "pending";
    });
    build.addCase(addAdmin.fulfilled, (state, action) => {
      state.addAdminStatus = "fulfilled";
      // state.admins = [...state.admins, action.payload]; // Uncomment it when admin will be return
    });
    build.addCase(addAdmin.rejected, (state) => {
      state.addAdminStatus = "rejected";
    });
  },
});

const self = (state) => state.admin;

export const selectAllNotSuperAdmins = createSelector(self, (state) =>
  state.admins.filter((admin) => !admin.super)
);

export const {} = adminsSlice.actions;

export default adminsSlice.reducer;
