import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  NavLink,
  Route,
  useLocation,
  useHistory,
  Switch,
  Redirect,
} from "react-router-dom";
import clsx from "clsx";
import styles from "./Home.module.scss";

import { logout } from "../../APIs/Auth";

import {
  ContentManagment,
  Verses,
  UserManagment,
  Settings,
  Templates,
} from "../../dashboardSection";
import { NotFound } from "../../pages";

import { fetchUser } from "../../store/user";

import ICONS from "../../assets/icons";
import adminAvatar from "../../assets/adminAvatar.png";

import ROUTES from "../../route/routes";

const Home = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = React.useState({
    showNavBar: false,
  });
  const { user } = useSelector((store) => store.user);
  const { showNavBar } = state;

  useEffect(() => {
    if (!user && localStorage.getItem("accessToken")) {
      dispatch(fetchUser());
    }
  }, []);

  if (!localStorage.getItem("accessToken")) return <Redirect to="/sign-in" />;

  if (location.pathname === "/") return <Redirect to={ROUTES.verses} />;

  const tabs = [
    {
      name: "Add a verse",
      path: ROUTES.verses,
      icon: ICONS.navBar.verses,
      isSuperAdmin: false,
    },
    {
      name: "Content Managment",
      path: ROUTES.contentManagment,
      icon: ICONS.navBar.contentManagment,
      isSuperAdmin: false,
    },
    {
      name: "User Managment",
      path: ROUTES.userManagment,
      icon: ICONS.navBar.userManagment,
      isSuperAdmin: false,
    },
    {
      name: "Template categories",
      path: ROUTES.templates,
      icon: ICONS.navBar.userManagment,
      isSuperAdmin: false,
    },
    {
      name: "Settings",
      path: ROUTES.settings,
      icon: ICONS.navBar.settings,
      isSuperAdmin: true,
    },
  ];

  const tabClickHandler = () =>
    setState((prev) => ({ ...prev, showNavBar: false }));

  const handleLogout = () => {
    logout();
    history.push("/sign-in");
  };

  return (
    <div className={styles.home}>
      <div className={styles.home__box}>
        <div
          className={clsx(
            styles.home__menu,
            showNavBar && styles.home__menu_active
          )}
        >
          <div className={styles.home__menuLogo}>CBT Diary</div>
          <div className={styles.home__adminTools}>Admin tools</div>
          <ul className={styles.home__navList}>
            {tabs.map((tab, index) => {
              if (tab.isSuperAdmin && !user?.super) return null;
              return (
                <li onClick={tabClickHandler} key={index}>
                  {tab?.path ? (
                    <NavLink to={tab.path} activeClassName={styles.active}>
                      {tab.icon}
                      {tab.name}
                    </NavLink>
                  ) : (
                    <span>
                      {tab.icon}
                      {tab.name}
                    </span>
                  )}
                </li>
              );
            })}
            <li className={styles.home__logOut}>
              <div className={styles.home__logOut_wrapper}>
                <div className={styles.home__logOut_wrapper_img}>
                  <img src={adminAvatar} alt="Avatar" />
                </div>
                <div className={styles.home__logOut_wrapper_content}>
                  <div className={styles.home__logOut_wrapper_content_admin}>
                    Admin
                  </div>
                  <div
                    className={styles.home__logOut_wrapper_content_logout}
                    onClick={handleLogout}
                  >
                    Log Out
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className={styles.home__right}>
          <Switch>
            <Route exact path={ROUTES.verses} component={Verses} />
            <Route
              exact
              path={ROUTES.contentManagment}
              component={ContentManagment}
            />
            <Route
              exact
              path={ROUTES.userManagment}
              component={UserManagment}
            />
            <Route exact path={ROUTES.templates} component={Templates} />
            <Route exact path={ROUTES.settings} component={Settings} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Home;
