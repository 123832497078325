import React from "react";
import { BarLoader } from "react-spinners";

const Loader = ({ loading }) => (
  <BarLoader
    color="#4DBD47"
    loading={loading}
    css={{ display: "block" }}
    width="100%"
  />
);

export default Loader;
