import React from "react";
import styles from "./Textarea.module.scss";

const Textarea = ({ value, onChange, label, formHelper, ...rest }) => {
  const changeHandler = (e) =>
    formHelper
      ? onChange(e.target.name, e.target.value)
      : onChange(e.target.value);

  return (
    <div>
      {label && <div className={styles.textarea_label}>{label}</div>}
      <textarea
        className={styles.textarea}
        value={value}
        onChange={changeHandler}
        {...rest}
      />
    </div>
  );
};

export default Textarea;
