//----------------Basic imports-------------------------------------------
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./ContentManagment.module.scss";

import { Button, Popup, Loader } from "../../components";
import Filter from "./Filter";
import ContentItem from "./ContentItem";

import {
  fetchVerses,
  removeVers,
  selectVersesWithPagination,
  selectVersesCount,
} from "../../store/verses";

const LIMIT = 10;

const ContentManagment = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [versIdForDelete, setVersIdForDelete] = useState(null);
  const [paginationPage, setPaginationPage] = useState(1);
  const dispatch = useDispatch();
  const verses = useSelector((state) =>
    selectVersesWithPagination(state, { page: paginationPage, limit: LIMIT })
  );
  const versesCount = useSelector(selectVersesCount);
  const fetchVersesStatus = useSelector(
    (state) => state.verses.fetchVersesStatus
  );

  useEffect(() => {
    dispatch(fetchVerses());
  }, []);

  const openDeletePopup = (id) => {
    setVersIdForDelete(id);
    setIsOpenModal(true);
  };

  const renderContent = () => {
    return verses.map((v) => (
      <ContentItem key={v._id} verse={v} onDelete={openDeletePopup} />
    ));
  };

  const handleCancelPopup = () => {
    if (isOpenModal) {
      setVersIdForDelete(null);
      setIsOpenModal(false);
    }
  };

  const handleDeleteVers = async () => {
    await dispatch(removeVers(versIdForDelete));
    setVersIdForDelete(null);
    setIsOpenModal(false);
  };

  const showMore = () => {
    setPaginationPage(paginationPage + 1);
  };

  return (
    <div className={styles.contentManagment}>
      <div className={clsx(styles.contentManagment_wrapper, "article_shadow")}>
        <h3>Content managment</h3>
        <div className={styles.contentManagment_filterWrapper}>
          <Filter />
        </div>
        <div className={styles.contentManagment_contentList}>
          {fetchVersesStatus === "fulfilled" && renderContent()}
          <Loader loading={fetchVersesStatus === "pending"} />
          <div className={styles.contentManagment_contentList_buttonWrapper}>
            <div className={styles.contentManagment_contentList_button}>
              {versesCount !== verses.length && (
                <Button onClick={showMore}>View more</Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Popup
        isOpen={isOpenModal}
        title="Are you sure you want to delete this post?"
        onCancel={handleCancelPopup}
        onConfirm={handleDeleteVers}
      />
    </div>
  );
};

export default ContentManagment;
