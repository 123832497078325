import { responseHandler } from "../helpers";
import fetchRequest from "../utils/fetchRequest";

export const getUsers = async () => {
  const response = await fetchRequest("admin/users", "GET");

  const error = responseHandler(response, "fetch usesr");

  if (!error) {
    return response.users;
  } else {
    alert(error);
  }
};

export const downloadUsersTable = async () => {
  const response = await fetchRequest("admin/table", "GET");

  const error = responseHandler(response, "create table");

  if (!error) {
    return response.file;
  } else {
    alert(error);
  }
};
