import { refreshToken } from "../APIs/refreshToken";

const fetchRequest = async (url, method, body, formData = false, Token) => {
  let token = Token || localStorage.getItem("accessToken");
  const host = process.env.REACT_APP_DEFAULT_URL;
  let headers = {};

  try {
    if (formData === true) {
      headers = {};
    } else
      headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
      };

    if (token) headers.Authorization = `Bearer ${token}`;

    const response = await fetch(`${host}/${url}`, {
      crossDomain: true,
      method: method.toUpperCase(),
      headers: headers,
      body: formData === true ? body : JSON.stringify(body),
    });

    if (response.status !== 200 && response.status !== 201) {
      // const data = await response.json();

      console.log(response, "---error with create verse");
    }

    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      return { ...data, status: response.status };
    } else if (response.status === 400) {
      const data = await response.json();
      console.log("error", data);
      return { ...data, status: response.status };
    } else if (response.status === 401) {
      if (localStorage.getItem("accessToken")) {
        const refreshed = await refreshToken();
        if (refreshed === true) {
          const newResponse = fetchRequest(url, method, body);
          return newResponse;
        } else {
          localStorage.removeItem("accessToken");
          alert("Time of your workin session is out, please login again.");
        }
      }
    } else if (response.status === (403 || 404)) {
      const data = await response.json();
      console.log("error", data);
      return { ...data, status: response.status };
    } else if (response.status === 409) {
      const data = await response.json();
      console.log("error", data);
      return { ...data, status: response.status };
    } else if (response.status === 422) {
      const data = await response.json();
      console.log("error", data);
      return { ...data, status: response.status };
    } else if (response.status === 500) {
      alert("Sorry the server is not available right now please try later");
    } else {
      debugger;
      const data = await response.json();
      return data;
    }
  } catch (e) {
    console.log(e);
  }
};

export default fetchRequest;
