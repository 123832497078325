//---------------------Basic imports-----------------------
import React from "react";
import clsx from "clsx";
import "./Button.scss";

const Button = ({
  children,
  onClick,
  variant,
  disabled = false,
  small = false,
  type = "button",
}) => {
  //---------------------Layout-----------------------

  return (
    <button
      type={type}
      className={clsx(
        "button",
        small && `button_small`,
        variant && `button_${variant}`
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
