import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import { createVers, getVerses, deleteVers, editVers } from "../../APIs/Verses";

const initialState = {
  verses: [],
  fetchVersesStatus: "idle",
  createVersStatus: "idle",
  deleteVersStatus: "idle",
  updateVersStatus: "idle",
};

export const fetchVerses = createAsyncThunk("verses/fetchVerses", async () => {
  const verses = await getVerses();

  return verses;
});

export const addVers = createAsyncThunk("verses/addVerse", async (versData) => {
  const data = await createVers(versData);

  return data;
});

export const removeVers = createAsyncThunk("verses/removeVers", async (id) => {
  await deleteVers(id);

  return id;
});

export const updateVers = createAsyncThunk("verses/editVers", async (vers) => {
  const updatedVers = await editVers(vers);

  return updatedVers;
});

export const versesSlice = createSlice({
  name: "verses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addVers.pending, (state) => {
        state.createVersStatus = "pending";
      })
      .addCase(addVers.fulfilled, (state) => {
        state.createVersStatus = "fulfilled";
      })
      .addCase(addVers.rejected, (state) => {
        state.createVersStatus = "rejected";
      })
      .addCase(fetchVerses.pending, (state) => {
        state.fetchVersesStatus = "pending";
      })
      .addCase(fetchVerses.fulfilled, (state, action) => {
        state.fetchVersesStatus = "fulfilled";
        state.verses = action.payload;
      })
      .addCase(fetchVerses.rejected, (state) => {
        state.fetchVersesStatus = "rejected";
      })
      .addCase(removeVers.pending, (state) => {
        state.deleteVersStatus = "pending";
      })
      .addCase(removeVers.fulfilled, (state, action) => {
        state.deleteVersStatus = "fulfilled";
        state.verses = state.verses.filter((v) => v._id !== action.payload);
      })
      .addCase(removeVers.rejected, (state) => {
        state.deleteVersStatus = "rejected";
      })
      .addCase(updateVers.pending, (state) => {
        state.updateVersStatus = "pending";
      })
      .addCase(updateVers.fulfilled, (state, action) => {
        state.updateVersStatus = "fulfilled";
        state.verses = state.verses.map((v) =>
          v._id === action.payload._id ? action.payload : v
        );
      })
      .addCase(updateVers.rejected, (state) => {
        state.updateVersStatus = "rejected";
      });
  },
});

const self = (state) => state.verses;

export const selectVersesCount = createSelector(
  self,
  (state) => state.verses.length
);

export const selectVersesWithPagination = createSelector(
  (state) => state.verses.verses,
  (_, options) => options,
  (verses, { limit, page }) => verses.slice(0, page * limit)
);

export const {} = versesSlice.actions;

export default versesSlice.reducer;
