const categories = [
  { value: "Happy", label: "Happy" },
  { value: "Mad", label: "Mad" },
  { value: "Sad", label: "Sad" },
  { value: "Worried", label: "Worried" },
  { value: "Template", label: "Template" },
];

const categoriesWithoutTemplate = [
  { value: "Happy", label: "Happy" },
  { value: "Mad", label: "Mad" },
  { value: "Sad", label: "Sad" },
  { value: "Worried", label: "Worried" },
];

const subCategories = {
  Happy: [
    { value: "Joyful", label: "Joyful" },
    { value: "Content", label: "Content" },
    { value: "Carefree", label: "Carefree" },
    { value: "Proud", label: "Proud" },
    { value: "Hopeful", label: "Hopeful" },
    { value: "Confident", label: "Confident" },
    { value: "Peaceful", label: "Peaceful" },
    { value: "Thankful", label: "Thankful" },
    { value: "Ecstatic", label: "Ecstatic" },
    { value: "Cheerful", label: "Cheerful" },
  ],
  Mad: [
    { value: "Angry", label: "Angry" },
    { value: "Bitter", label: "Bitter" },
    { value: "Rageful", label: "Rageful" },
    { value: "Annoyed", label: "Annoyed" },
    { value: "Frustrated", label: "Frustrated" },
    { value: "Offended", label: "Offended" },
    { value: "Disgusted", label: "Disgusted" },
    { value: "Jealous", label: "Jealous" },
    { value: "Disappointed", label: "Disappointed" },
    { value: "Protective", label: "Protective" },
  ],
  Sad: [
    { value: "Somber", label: "Somber" },
    { value: "Blue", label: "Blue" },
    { value: "Grieving", label: "Grieving" },
    { value: "Weepy", label: "Weepy" },
    { value: "Melancholic", label: "Melancholic" },
    { value: "Sorrowful", label: "Sorrowful" },
    { value: "Pessimistic", label: "Pessimistic" },
    { value: "Wistful", label: "Wistful" },
    { value: "Guilty", label: "Guilty" },
    { value: "Lonely", label: "Lonely" },
  ],
  Worried: [
    { value: "Anxious", label: "Anxious" },
    { value: "Fearful", label: "Fearful" },
    { value: "Overwhelmed", label: "Overwhelmed" },
    { value: "Insecure", label: "Insecure" },
    { value: "Rejected", label: "Rejected" },
    { value: "Confused", label: "Confused" },
    { value: "Vulnerable", label: "Vulnerable" },
    { value: "Weak", label: "Weak" },
    { value: "Timid", label: "Timid" },
    { value: "Apprehensive", label: "Apprehensive" },
  ],
};

export { categories, subCategories, categoriesWithoutTemplate };
