import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./user";
import adminReducer from "./admins";
import versesReducer from "./verses";
import usersReducer from "./users";

export const store = configureStore({
  reducer: {
    user: userReducer,
    admin: adminReducer,
    verses: versesReducer,
    users: usersReducer,
  },
});
