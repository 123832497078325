const VersesIcon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7508 18.9397H11.4978C11.0838 18.9397 10.7478 18.6037 10.7478 18.1897C10.7478 17.7757 11.0838 17.4397 11.4978 17.4397H18.7508C19.1648 17.4397 19.5008 17.7757 19.5008 18.1897C19.5008 18.6037 19.1648 18.9397 18.7508 18.9397Z"
      fill="#808191"
    />
    <mask
      id="mask0_641_880"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="19"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000335693 0.000244141H17.1809V18.9398H0.000335693V0.000244141Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_641_880)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1106 2.01689L1.69561 13.7919C1.52461 14.0059 1.46161 14.2819 1.52461 14.5469L2.20561 17.4319L5.24461 17.3939C5.53361 17.3909 5.80061 17.2619 5.97761 17.0419C9.19461 13.0169 15.3276 5.34289 15.5016 5.11789C15.6656 4.85189 15.7296 4.47589 15.6436 4.11389C15.5556 3.74289 15.3246 3.42789 14.9916 3.22689C14.9206 3.17789 13.2356 1.86989 13.1836 1.82889C12.5496 1.32089 11.6246 1.40889 11.1106 2.01689ZM1.61361 18.9399C1.26661 18.9399 0.96461 18.7019 0.88361 18.3629L0.06461 14.8919C-0.10439 14.1729 0.06361 13.4309 0.52461 12.8549L9.94461 1.07289C9.94861 1.06889 9.95161 1.06389 9.95561 1.05989C10.9886 -0.175113 12.8566 -0.357113 14.1166 0.653887C14.1666 0.692887 15.8396 1.99289 15.8396 1.99289C16.4476 2.35489 16.9226 3.00189 17.1026 3.76789C17.2816 4.52589 17.1516 5.30789 16.7346 5.96889C16.7036 6.01789 16.6766 6.05989 7.14861 17.9799C6.68961 18.5519 6.00161 18.8849 5.26261 18.8939L1.62361 18.9399H1.61361Z"
        fill="#808191"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2234 8.68487C14.0634 8.68487 13.9034 8.63387 13.7664 8.52987L8.31442 4.34187C7.98642 4.08987 7.92442 3.61987 8.17642 3.28987C8.42942 2.96187 8.89942 2.90087 9.22842 3.15287L14.6814 7.33987C15.0094 7.59187 15.0714 8.06287 14.8184 8.39187C14.6714 8.58387 14.4484 8.68487 14.2234 8.68487Z"
      fill="#808191"
    />
  </svg>
);

export default VersesIcon;
