import { responseHandler } from "../helpers";
import fetchRequest from "../utils/fetchRequest";

export const createVers = async (versData) => {
  const response = await fetchRequest("admin/post", "POST", versData, true);

  // const error = responseHandler(response, "create verse");
  return response;

  // if (!error) {
  //   return response.success;
  // } else {
  //   alert(error);
  // }
};

export const getVerses = async () => {
  const response = await fetchRequest("admin/posts", "GET");

  const error = responseHandler(response, "create verse");

  if (!error) {
    return response.posts;
  } else {
    alert(error);
  }
};

export const deleteVers = async (postId) => {
  const response = await fetchRequest("admin/post", "DELETE", { postId });

  const error = responseHandler(response, "delete verse");

  if (!error) {
    return response.success;
  } else {
    alert(error);
  }
};

export const editVers = async (vers) => {
  const response = await fetchRequest("admin/post", "PUT", vers, true);

  const error = responseHandler(response, "edit vers");

  if (!error) {
    return response.post;
  } else {
    alert(error);
  }
};

export const getTemplates = async () => {
  const response = await fetchRequest("admin/templates", "GET");

  const error = responseHandler(response, "edit vers");

  if (!error) {
    return response.titles;
  } else {
    alert(error);
  }
};

export const updateTemplateOrders = async (templates) => {
  const response = await fetchRequest("admin/templates-order", "PUT", {
    templates,
  });

  const error = responseHandler(response, "edit vers");

  if (!error) {
    return response;
  } else {
    alert(error);
  }
};

export const createTemplate = async (title) => {
  const response = await fetchRequest("admin/template", "POST", { title });

  const error = responseHandler(response, "edit vers");

  if (!error) {
    return response.created;
  } else {
    alert(error);
  }
};

export const deleteTemplate = async (title) => {
  const response = await fetchRequest("admin/template", "DELETE", { title });

  const error = responseHandler(response, "edit vers");

  if (!error) {
    return response.created;
  } else {
    alert(error);
  }
};
