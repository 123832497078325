const SmallArrow = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 18L14.9293 12.0707C14.9683 12.0317 14.9683 11.9683 14.9293 11.9293L9 6"
      stroke="#9FA2B4"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default SmallArrow;
