import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/style.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import AlertTemplate from "react-alert-template-basic";
import { transitions, positions, Provider as AlertProvider } from "react-alert";

import { store } from "./store";

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 4000,
  offset: "30px",
  transition: transitions.FADE,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <App />
        </AlertProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
