import React from "react";
import clsx from "clsx";
import styles from "./Checkbox.module.scss";

import ICONS from "../../assets/icons";
const {
  utils: { checkmark },
} = ICONS;

const Checkbox = ({ checked, onChange }) => (
  <div
    className={clsx(styles.checkbox, checked && styles.checkbox_active)}
    onClick={() => onChange(!checked)}
  >
    {checked && checkmark}
  </div>
);

export default Checkbox;
