import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./Templates.module.scss";

import { getTemplates, updateTemplateOrders } from "../../APIs/Verses";

const Templates = () => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleDrop = (droppedItem) => {
    if (!droppedItem.destination) return;
    var updatedList = [...templates];

    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

    setTemplates(updatedList);
    updateOrder(updatedList);
  };

  const updateOrder = async (updatedList) => {
    try {
      const arrForUpdate = updatedList.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
      await updateTemplateOrders(arrForUpdate);

      alert("Updated order");
    } catch (e) {
      debugger;
    }
  };

  const fetchTemplates = async () => {
    try {
      const fetchedTemplates = await getTemplates();
      setTemplates(fetchedTemplates);
    } catch (e) {
      console.log(e);
      debugger;
    }
  };

  return (
    <div className={clsx(styles.page, "article_shadow")}>
      <h3 className={styles.page_title}>Templates</h3>

      <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="list-container">
          {(provided) => (
            <div
              className="list-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {templates.map((item, index) => (
                <Draggable key={item._id} draggableId={item._id} index={index}>
                  {(provided) => (
                    <div
                      className={styles.page_item}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      {item.title}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default Templates;
