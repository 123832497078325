import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getUser } from "../../APIs/Auth";

const initialState = {
  user: null,
  fetchUserStatus: "idle",
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const admin = await getUser();
  return admin;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (build) => {
    build
      .addCase(fetchUser.pending, (state) => {
        state.fetchUserStatus = "pending";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.fetchUserStatus = "fulfilled";
      })
      .addCase(fetchUser.rejected, (state) => {
        state.fetchUserStatus = "rejected";
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
