import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { Home, NotFound, SignIn } from "./pages";

function App() {
  // const history = useHistory();

  React.useEffect(() => {
    // history.push({ pathname: "/signIn" });
  }, []);
  return (
    <>
      <Switch>
        <Route exact path="/sign-in" component={SignIn} />
        <Route path="/" component={Home} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
