//----------------Basic imports-------------------------------------------
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import styles from "./UserManagment.module.scss";

import { Button, Loader } from "../../components";

import {
  fetchUsers,
  selectUsersCount,
  selectUsersWithPagination,
} from "../../store/users";

import { downloadUsersTable } from "../../APIs/Users";

const LIMIT = 10;

const UserManagment = () => {
  const [paginationPage, setPaginationPage] = useState(1);
  const usersCount = useSelector(selectUsersCount);
  const fetchUsersStatus = useSelector((state) => state.users.fetchUsersStatus);
  const users = useSelector((state) =>
    selectUsersWithPagination(state, { page: paginationPage, limit: LIMIT })
  );
  const dispatch = useDispatch();
  const linkRef = useRef(null);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  // Add date and pay statu when be available
  const renderUsers = () => {
    return users.map((user) => (
      <tr key={user._id}>
        <th>{user.name}</th>
        <th style={{ paddingRight: 10 }}>{user.phone || user.email}</th>
        <th className={styles.userManagment_thStatus}>Free</th>
        <th className={styles.userManagment_thDate}>
          {user.createdAt || "01/15/2022"}
        </th>
      </tr>
    ));
  };

  const showMore = () => {
    setPaginationPage(paginationPage + 1);
  };

  const downloadCvsUserTable = async () => {
    try {
      const file = await downloadUsersTable();

      const csvContent = atob(file);
      const blob = new Blob([csvContent], {
        type: "data:text/csv;base64",
      });
      const url = window.URL.createObjectURL(blob);
      linkRef.current.href = url;
      linkRef.current.setAttribute("download", "users.csv");
      linkRef.current.click();
    } catch (e) {
      alert("Somethin went wrong with download");
    }
  };

  return (
    <div className={clsx(styles.userManagment, "article_shadow")}>
      <header className={styles.userManagment_header}>
        <h3>User managment</h3>
        <div>
          <Button onClick={downloadCvsUserTable}>Download</Button>
        </div>
      </header>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone/Email</th>
            <th>Account status</th>
            <th>Registration date</th>
          </tr>
        </thead>
        <tbody>{fetchUsersStatus === "fulfilled" && renderUsers()}</tbody>
      </table>
      <Loader loading={fetchUsersStatus === "pending"} />
      {usersCount !== users.length && (
        <div className={styles.userManagment_buttonContainer}>
          <div className={styles.userManagment_buttonWrapper}>
            <Button onClick={showMore}>View more</Button>
          </div>
        </div>
      )}
      <a ref={linkRef} href="" style={{ display: "none" }} />
    </div>
  );
};

export default UserManagment;
