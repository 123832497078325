import { responseHandler } from "../helpers";
import fetchRequest from "../utils/fetchRequest";

export const auth = async (data) => {
  const response = await fetchRequest("admin/signin", "POST", data);

  const error = responseHandler(response, "login", "Wrong password or login");

  if (!error) {
    localStorage.setItem("accessToken", response.token);
    window.dispatchEvent(new Event("storage"));
    return response.admin;
  } else {
    alert(error);
    return false;
  }
};

export const getUser = async () => {
  const response = await fetchRequest("admin/current", "GET");

  const error = responseHandler(response, "login", "Wrong password or login");

  if (!error) {
    window.dispatchEvent(new Event("storage"));
    return response.admin;
  }
};

export const logout = async () => {
  localStorage.removeItem("accessToken");
  window.dispatchEvent(new Event("storage"));
};
