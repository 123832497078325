import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { Formik } from "formik";
import styles from "./SignIn.module.scss";

import ROUTES from "../../route/routes";

import VALIDATIONS from "../../validation";

import { Input, Button } from "../../components";

import birdLogo from "../../assets/bird.jpg";

import { auth } from "../../APIs/Auth";
import { setUser } from "../../store/user";

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  if (localStorage.getItem("accessToken"))
    return <Redirect to={ROUTES.verses} />;

  const submitHandler = async (values) => {
    const user = await auth(values);

    if (user) {
      dispatch(setUser(user));
      history.push(ROUTES.verses);
    }
  };

  return (
    <div className={styles.signIn}>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{ email: "", password: "" }}
        validationSchema={VALIDATIONS.loginSchema}
        onSubmit={submitHandler}
      >
        {({ errors, values, setFieldValue, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.signIn__inner}>
              <div className={styles.signIn__logoWrapper}>
                <img src={birdLogo} alt="Logo bird" />
              </div>
              <div className={styles.signIn__title}>Login to your account</div>
              <div className={styles.signIn__form}>
                <div className={styles.signIn__inputWrapper}>
                  <Input
                    formHelper
                    label="Email"
                    name="email"
                    value={values.email}
                    error={errors.email}
                    onChange={setFieldValue}
                  />
                </div>
                <div>
                  <Input
                    formHelper
                    label="Password"
                    name="password"
                    password
                    value={values.password}
                    error={errors.password}
                    onChange={setFieldValue}
                    type="password"
                  />
                </div>
                <div className={styles.signIn__buttonWrapper}>
                  <Button type="submit">LOGIN</Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SignIn;
