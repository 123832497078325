import { responseHandler } from "../helpers";
import fetchRequest from "../utils/fetchRequest";

export const getAdmins = async () => {
  const response = await fetchRequest("admin", "GET");

  const error = responseHandler(response, "fetch admins");

  if (!error) {
    return response.admins;
  } else {
    alert(error);
  }
};

export const deleteAdmin = async (adminId) => {
  const response = await fetchRequest("admin", "DELETE", { adminId });

  const error = responseHandler(response, "delete admin");

  if (!error) {
    return response.success;
  } else {
    alert(error);
  }
};

export const createAdmin = async (admin) => {
  const response = await fetchRequest("admin", "POST", admin);

  const error = responseHandler(response, "create admin");

  if (!error) {
    return response.success; // Change it when admin will be return in response
  } else {
    alert(error);
  }
};
