const ContentManagment = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5771 20.3686H5.75309C2.31209 20.3686 9.15527e-05 17.9536 9.15527e-05 14.3596V6.04562C9.15527e-05 2.45162 2.31209 0.0366211 5.75309 0.0366211H9.49209C9.90609 0.0366211 10.2421 0.372621 10.2421 0.786621C10.2421 1.20062 9.90609 1.53662 9.49209 1.53662H5.75309C3.16909 1.53662 1.50009 3.30662 1.50009 6.04562V14.3596C1.50009 17.0986 3.16909 18.8686 5.75309 18.8686H14.5771C17.1611 18.8686 18.8311 17.0986 18.8311 14.3596V10.3316C18.8311 9.91762 19.1671 9.58162 19.5811 9.58162C19.9951 9.58162 20.3311 9.91762 20.3311 10.3316V14.3596C20.3311 17.9536 18.0181 20.3686 14.5771 20.3686Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86768 13.4282H9.84468C10.2247 13.4282 10.5807 13.2812 10.8497 13.0122L18.3587 5.50324C18.6667 5.19524 18.8367 4.78524 18.8367 4.34924C18.8367 3.91224 18.6667 3.50124 18.3587 3.19324L17.1417 1.97624C16.5047 1.34124 15.4687 1.34124 14.8307 1.97624L7.35768 9.44924C7.09868 9.70824 6.95168 10.0522 6.94268 10.4172L6.86768 13.4282ZM9.84468 14.9282H6.09868C5.89668 14.9282 5.70268 14.8462 5.56168 14.7012C5.42068 14.5572 5.34368 14.3622 5.34868 14.1592L5.44268 10.3802C5.46168 9.62824 5.76468 8.92124 6.29668 8.38824H6.29768L13.7707 0.915244C14.9927 -0.304756 16.9797 -0.304756 18.2017 0.915244L19.4187 2.13224C20.0117 2.72424 20.3377 3.51124 20.3367 4.34924C20.3367 5.18724 20.0107 5.97324 19.4187 6.56424L11.9097 14.0732C11.3587 14.6242 10.6247 14.9282 9.84468 14.9282Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.731 7.9166C17.539 7.9166 17.347 7.8436 17.201 7.6966L12.635 3.1306C12.342 2.8376 12.342 2.3626 12.635 2.0696C12.928 1.7766 13.402 1.7766 13.695 2.0696L18.261 6.6366C18.554 6.9296 18.554 7.4036 18.261 7.6966C18.115 7.8436 17.923 7.9166 17.731 7.9166Z"
      fill="#808191"
    />
  </svg>
);

export default ContentManagment;
