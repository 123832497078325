import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";

import { getUsers } from "../../APIs/Users";

const initialState = {
  users: [],
  fetchUsersStatus: "idle",
};

export const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const users = await getUsers();

  return users;
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (build) => {
    build
      .addCase(fetchUsers.pending, (state) => {
        state.fetchUsersStatus = "pending";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.fetchUsersStatus = "fulfilled";
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.fetchUsersStatus = "rejected";
      });
  },
});

const self = (state) => state.users;

export const selectUsersCount = createSelector(
  self,
  (state) => state.users.length
);

export const selectUsersWithPagination = createSelector(
  (state) => state.users.users,
  (_, options) => options,
  (verses, { limit, page }) => verses.slice(0, page * limit)
);

export const {} = usersSlice.actions;

export default usersSlice.reducer;
