import * as yup from "yup";
import validationValues from "../values";

const addAdminSchema = yup.object().shape({
  email: validationValues.email,
  password: validationValues.password,
  repeatPassword: validationValues.repeatPassword,
});

export default addAdminSchema;
