import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Collapse } from "react-collapse";
import { useFormik } from "formik";
import { useAlert } from "react-alert";
import moment from "moment";
import clsx from "clsx";
import styles from "./ContentItem.module.scss";

import { Input, Textarea, Select, Button, Preview } from "../../../components";
import { editVersSchema } from "../../../validation/schemas";

import { updateVers } from "../../../store/verses";

import {
  categoriesWithoutTemplate as categories,
  subCategories,
} from "../../../constants/categories";

import backgrounds from "../../../assets/defaultBackgrounds";
import ICONS from "../../../assets/icons";
const {
  utils: { arrowTop, print, edit },
} = ICONS;

const ContentItem = ({ verse, onDelete }) => {
  const { createdAt, category, subcategory, cover, name, text, _id } = verse;

  const {
    values,
    setFieldValue,
    handleSubmit,
    isValid,
    validateForm,
    resetForm,
  } = useFormik({
    initialValues: {
      name,
      text,
      category,
      subcategory: subcategory === "null" ? null : subcategory,
    },
    validationSchema: editVersSchema,
    onSubmit: handleEditVers,
    enableReinitialize: true,
  });
  const [isContentOpen, setIsContentOpen] = useState(false);
  const [coverImg, setCoverImg] = useState(cover);
  const dispatch = useDispatch();
  const Alert = useAlert();
  const inputFileRef = useRef(null);

  const handleEditCover = () => {
    inputFileRef.current.click();
  };

  const handleChangeCover = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImg(file);
    }
  };

  async function handleEditVers(values) {
    const fd = new FormData();
    fd.append("postId", _id);

    for (let key in values) {
      fd.append(key, values[key]);
    }

    if (coverImg !== cover) {
      fd.append("cover", coverImg);
    }

    await dispatch(updateVers(fd));
    Alert.success("Vers was updated");
  }

  const handleCloseUpdate = () => {
    if (!isContentOpen) {
      resetForm();
      setCoverImg(cover);
    }
    setIsContentOpen(!isContentOpen);
  };

  const previewImage = coverImg
    ? `url(${process.env.REACT_APP_DEFAULT_URL}/images/${coverImg})`
    : "";

  const isTemplate = category === "Template";

  return (
    <div>
      <div className={styles.item}>
        <div className={styles.item_main_date}>
          {moment(createdAt).format("MM/DD/Y")}
        </div>
        <div className={styles.item_main}>
          <div className={styles.item_main_avatar}>
            <div
              className={styles.item_main_avatar_default}
              style={{
                backgroundImage: cover
                  ? `url(${process.env.REACT_APP_DEFAULT_URL}/images/${cover})`
                  : `url(${backgrounds[category]})`,
              }}
            />
          </div>
          <div className={styles.item_main_info}>
            <h4 className={styles.item_main_info_title}>{name}</h4>
            <div className={styles.item_main_info_subtitle_wrapper}>
              <h6 className={styles.item_main_info_subtitle}>{text}</h6>
            </div>
            <div
              className={styles.item_main_info_information}
              onClick={handleCloseUpdate}
            >
              <div>
                <span>View all information</span>
              </div>
              <div
                className={clsx(
                  styles.item_main_info_information_arrowIcon,
                  isContentOpen && styles.item_main_info_information_open
                )}
              >
                {arrowTop}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.item_category}>
          <div className={styles.item_category_wrapper}>
            <div
              className={clsx(
                styles.item_category_feel,
                styles[`item_category_feel_${category}`]
              )}
            />
            <div className={clsx(styles.item_category_title)}>{category}</div>
          </div>
        </div>
        <div className={styles.item_subcategory}>
          {subcategory !== "null" ? subcategory : ""}
        </div>
        <div className={styles.item_actions}>
          <div className={styles.item_actions_item} onClick={handleCloseUpdate}>
            {edit}
          </div>
          <div
            className={styles.item_actions_item}
            onClick={() => onDelete(_id)}
          >
            {print}
          </div>
        </div>
      </div>

      <Collapse isOpened={isContentOpen}>
        <div className={styles.item_content}>
          {!isTemplate && (
            <div className={styles.item_content_bg}>
              <p>Photo</p>
              {typeof coverImg !== "string" && coverImg !== null ? (
                <Preview file={coverImg} height="400px" />
              ) : (
                <div
                  className={styles.item_content_bg_img}
                  style={{ backgroundImage: previewImage }}
                  onClick={handleEditCover}
                >
                  {!previewImage && "Cover Image"}
                </div>
              )}
              <div className={styles.item_content_bg_img_actions}>
                <div className={styles.item_content_bg_img_actions_btn}>
                  <Button small onClick={handleEditCover} variant="outlined">
                    Edit
                  </Button>
                </div>
              </div>
            </div>
          )}
          <div
            className={styles.item_content_info}
            style={{ flex: isTemplate ? 1 : 0.49 }}
          >
            <div className={styles.item_content_info_inputWrapper}>
              <Input
                label="Title"
                placeholder="Text"
                formHelper
                name="name"
                value={values.name}
                onChange={setFieldValue}
              />
            </div>
            <div className={styles.item_content_info_inputWrapper}>
              <Textarea
                label="Post Description"
                placeholder="Text"
                name="text"
                value={values.text}
                formHelper
                onChange={setFieldValue}
              />
            </div>
            <div className={styles.item_content_info_inputWrapper}>
              <Select
                label="Category"
                name="category"
                options={categories}
                value={{ value: values.category, label: values.category }}
                onChange={async ({ value }) => {
                  await setFieldValue("subcategory", null);
                  setFieldValue("category", value);
                  validateForm();
                }}
                isDisabled={isTemplate}
              />
            </div>
            <div className={styles.item_content_info_inputWrapper}>
              <Select
                label="Sub category"
                name="subcategory"
                placeholder="Choose a subcategory"
                options={subCategories[values.category]}
                value={
                  values.subcategory
                    ? {
                        value: values.subcategory,
                        label: values.subcategory,
                      }
                    : null
                }
                onChange={(option) => {
                  setFieldValue("subcategory", option ? option.value : null);
                }}
                isClearable
                isDisabled={isTemplate}
              />
            </div>
            <div className={styles.item_content_info_inputWrapper}>
              <div className={styles.item_content_info_buttonWrapper}>
                <div className={styles.item_content_info_button}>
                  <Button onClick={handleSubmit} disabled={!isValid}>
                    Save changes
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <input
        ref={inputFileRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleChangeCover}
        accept="image/jpeg"
      />
    </div>
  );
};

export default ContentItem;
