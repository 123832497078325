import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import clsx from "clsx";
import styles from "./Settings.module.scss";

import { Input, Button, Popup, Loader } from "../../components";

import {
  fetchAdmins,
  removeAdmin,
  addAdmin,
  selectAllNotSuperAdmins,
} from "../../store/admins";

import { addAdminSchema } from "../../validation/schemas";

const Settings = () => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [adminsIdForDelete, setAdminsIdForDelete] = useState(null);
  const dispatch = useDispatch();
  const { fetchAdminsStatus } = useSelector((state) => state.admin);
  const admins = useSelector(selectAllNotSuperAdmins);

  useEffect(() => {
    if (fetchAdminsStatus !== "fulfilled") {
      dispatch(fetchAdmins());
    }
  }, []);

  const openConfirmPopup = (id) => {
    setIsOpenPopup(true);
    setAdminsIdForDelete(id);
  };

  const renderAdminList = () => {
    return admins.map((admin) => (
      <div key={admin._id} className={styles.settings_adminList_listItem}>
        <div className={styles.settings_adminList_listItem_email}>
          {admin.email}
        </div>
        <div className={styles.settings_adminList_listItem_button}>
          <Button onClick={() => openConfirmPopup(admin._id)} small>
            Delete
          </Button>
        </div>
      </div>
    ));
  };

  const createAdmin = async ({ email, password }, form) => {
    await dispatch(addAdmin({ email, password }));
    await dispatch(fetchAdmins()); // Change it when admin will return after POST request
    form.resetForm();
  };

  const handleCancelPopup = () => {
    if (isOpenPopup) {
      setIsOpenPopup(false);
      setAdminsIdForDelete(null);
    }
  };

  const handleDeleteAdmin = async () => {
    await dispatch(removeAdmin(adminsIdForDelete));
    setIsOpenPopup(false);
  };

  return (
    <div className={styles.settings}>
      <div className={clsx(styles.settings_createAdmin, "article_shadow")}>
        <h3 className={styles.settings_createAdmin_h3}>Create new admin</h3>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={addAdminSchema}
          initialValues={{ email: "", password: "", repeatPassword: "" }}
          onSubmit={createAdmin}
        >
          {({ setFieldValue, values, handleSubmit, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.settings_createAdmin_inputWrapper}>
                  <Input
                    formHelper
                    value={values.email}
                    name="email"
                    onChange={setFieldValue}
                    placeholder="example@gmail.com"
                    label="Email"
                    error={errors.email}
                  />
                </div>
                <div className={styles.settings_createAdmin_inputWrapper}>
                  <Input
                    formHelper
                    value={values.password}
                    onChange={setFieldValue}
                    name="password"
                    label="Password"
                    placeholder="********"
                    error={errors.password}
                  />
                </div>
                <div className={styles.settings_createAdmin_inputWrapper}>
                  <Input
                    formHelper
                    value={values.repeatPassword}
                    onChange={setFieldValue}
                    name="repeatPassword"
                    label="Repeat password"
                    placeholder="********"
                    error={errors.repeatPassword}
                  />
                </div>
                <div className={styles.settings_createAdmin_buttonWrapper}>
                  <Button type="submit">Confirm</Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
      <div className={clsx(styles.settings_adminList, "article_shadow")}>
        <div className={styles.settings_adminList_list}>
          {renderAdminList()}
          <Loader loading={fetchAdminsStatus === "pending"} />
        </div>
      </div>
      <Popup
        title="Are you sure you want to delete this admin?"
        isOpen={isOpenPopup}
        onCancel={handleCancelPopup}
        onConfirm={handleDeleteAdmin}
      />
    </div>
  );
};

export default Settings;
