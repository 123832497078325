//--------------Basic imports-----------------------
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import clsx from "clsx";
import styles from "./Verses.module.scss";

import { Select, Button, Input, Textarea, Preview } from "../../components";

import { addVers } from "../../store/verses";
import {
  getTemplates,
  createTemplate,
  deleteTemplate,
} from "../../APIs/Verses";

import { categories, subCategories } from "../../constants/categories";
import { createVers } from "../../APIs/Verses";

const Verses = () => {
  const [verseName, setVerseName] = useState("");
  const [verseText, setVerseText] = useState("");
  const [category, setCategory] = useState(null);
  const [subcategory, setSubcategory] = useState(null);
  const [cover, setCover] = useState(null);

  const inputFileRef = useRef(null);
  const dispatch = useDispatch();
  const alertModal = useAlert();

  const [subcategoryOptions, setSubcategoryOptions] = useState(null);

  const fetchTemplatesTitles = async () => {
    try {
      const templates = await getTemplates();
      setSubcategoryOptions(
        templates.map((template) => ({
          value: template.title,
          label: template.title,
        }))
      );
    } catch (e) {
      console.log(e);
      debugger;
    }
  };

  useEffect(() => {
    if (category) {
      setSubcategory(null);

      // Get templates name category
      if (category.value === "Template") {
        setCover(null);
        fetchTemplatesTitles();
        return;
      }

      setSubcategoryOptions(subCategories[category.value]);
    }
  }, [category]);

  const resetData = () => {
    setVerseName("");
    setVerseText("");
    setCategory(null);
    setSubcategory(null);
    setCover(null);
  };

  const handleSlectImage = () => {
    inputFileRef.current.click();
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg") {
        setCover(e.target.files[0]);
      } else {
        alert('You can select only "image/jpeg" fromat');
      }
    }
  };

  const createVerse = async () => {
    const fd = new FormData();
    fd.append("category", category.value);
    fd.append("subcategory", subcategory ? subcategory.value : null);
    fd.append("name", verseName);
    fd.append("text", verseText);

    if (cover) {
      fd.append("cover", cover);
    }

    // await dispatch(addVers(fd));
    const resp = await createVers(fd);
    if (resp && resp.success) {
      resetData();
      alertModal.success("Verse added");
    } else {
      alert("Image is too large");
      console.log(resp);
    }
  };

  const checkAvailableCreate = (input, options) => {
    if (options && input) {
      return options.findIndex((option) => option.label === input) === -1;
    }
  };

  const createTemplateCategory = async (value) => {
    await createTemplate(value);
    fetchTemplatesTitles();
  };

  const handleDeleteTemplateCategory = async () => {
    await deleteTemplate(subcategory.value);
    setSubcategory(null);
    fetchTemplatesTitles();
  };

  const isDisabledSave = !verseName || !verseText || !category;

  return (
    <div className={clsx(styles.verses, "article_shadow")}>
      <div className={clsx(styles.verses__category)}>
        <div className={styles.verses__selectWrapper}>
          <div className={styles.verses__selectWrapper__name}>
            Category name*
          </div>
          <div className={styles.verses__selectWrapper__select}>
            <Select
              options={categories}
              value={category}
              onChange={setCategory}
              placeholder="Choose a category"
            />
          </div>
        </div>
        <div className={styles.verses__selectWrapper}>
          <div className={styles.verses__selectWrapper__name}>
            Sub category*
          </div>
          <div className={styles.verses__selectWrapper__select}>
            <Select
              options={subcategoryOptions}
              placeholder="Choose a subcategory"
              value={subcategory}
              onDisableCreate={checkAvailableCreate}
              onChange={setSubcategory}
              onCreateOption={createTemplateCategory}
              isDisabled={!category}
              isClearable
            />
          </div>
          {category && category.value === "Template" && subcategory && (
            <div className={styles.verses__deleteCategoryButton}>
              <Button onClick={handleDeleteTemplateCategory}>
                Delete category
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={clsx(styles.verses__post)}>
        {category && category.value !== "Template" && (
          <div>
            <p className={styles.verses__post_preview_label}>Max size 700 kb</p>
            {cover ? (
              <div className={styles.verses__post_preview}>
                <Preview file={cover} />
                <div
                  className={styles.verses__post_preview_chooseOther}
                  onClick={handleSlectImage}
                >
                  Choose other image
                </div>
              </div>
            ) : (
              <div
                className={styles.verses__post_uploadBlock}
                onClick={handleSlectImage}
              >
                Click to upload cover (optional)
              </div>
            )}
          </div>
        )}
        <div className={styles.verses__post_inputWrapper}>
          <Input
            label="Verse name"
            placeholder="Text"
            value={verseName}
            onChange={(value) => setVerseName(value)}
          />
        </div>
        <div>
          <Textarea
            label="Verse text"
            value={verseText}
            onChange={setVerseText}
            placeholder="Text"
          />
        </div>
        <div className={styles.verses__post_button_wrapper}>
          <div className={styles.verses__post_button}>
            <Button onClick={createVerse} disabled={isDisabledSave}>
              Save and publish
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.verses_inputWrapper}>
        <input
          ref={inputFileRef}
          type="file"
          onChange={handleUploadFile}
          accept="image/jpeg"
        />
      </div>
    </div>
  );
};

export default Verses;
