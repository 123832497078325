//------------------Nav bar icons----------------------------
import VersesIcon from "./navBar/versesIcon";
import ContentManagment from "./navBar/contentManagment";
import UserManagment from "./navBar/userManagment";
import Settings from "./navBar/settings";

//------------------Utils icons----------------------------
import MoreIcon from "./utils/moreIcon";
import SmallArrowIcon from "./utils/smallArrowIcon";
import SortIcon from "./utils/sortIcon";
import DeleteIcon from "./utils/deleteIcon";
import CloseIcon from "./utils/CloseIcon";
import CheckIcon from "./utils/checkmark";
import ArrowTopIcon from "./utils/arrowTop";
import PrintIcon from "./utils/print";
import EditIcon from "./utils/edit";

//-------------------Other icons----------------------------

const ICONS = {
  utils: {
    more: <MoreIcon />,
    smallArrow: <SmallArrowIcon />,
    sortIcon: <SortIcon />,
    delete: <DeleteIcon />,
    close: <CloseIcon />,
    checkmark: <CheckIcon />,
    arrowTop: <ArrowTopIcon />,
    print: <PrintIcon />,
    edit: <EditIcon />,
  },
  navBar: {
    verses: <VersesIcon />,
    contentManagment: <ContentManagment />,
    userManagment: <UserManagment />,
    settings: <Settings />,
  },
};

export default ICONS;
