import React, { useEffect, useState } from "react";
import "./styles.scss";

const Preview = ({ file, height = "200px" }) => {
  const [previewSrc, setPreviewSrc] = useState(null);

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewSrc(reader.result);
    };
  }, [file]);

  return (
    <div
      className="preview-image"
      style={{ backgroundImage: `url(${previewSrc})`, height }}
    />
  );
};

export default Preview;
