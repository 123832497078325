import * as yup from "yup";
import validationValues from "../values";

const editVersSchema = yup.object().shape({
  name: validationValues.versName,
  text: validationValues.versText,
  category: validationValues.versCategory,
  subcategory: validationValues.versSubcategory,
});

export default editVersSchema;
