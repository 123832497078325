import * as yup from "yup";

const validationValues = {
  login: yup.string().min(2, "Too short!").required("Required!"),
  password: yup.string().min(5, "Too short!").required("Required!"),
  email: yup.string().email("Incorrect email").required("Required"),
  repeatPassword: yup
    .string()
    .min(5)
    .oneOf([yup.ref("password")], "Password do not match")
    .required("Required"),
  versName: yup.string().required("Required"),
  versText: yup.string().required("Required"),
  versCategory: yup.string().required("Required"),
  versSubcategory: yup.string().nullable(true),
};

export default validationValues;
