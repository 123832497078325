const UserManagment = () => (
  <svg
    width="22"
    height="17"
    viewBox="0 0 22 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8868 8.14651C16.5188 8.14651 16.1978 7.87551 16.1448 7.50051C16.0878 7.08951 16.3728 6.71051 16.7838 6.65351C17.8238 6.50751 18.6088 5.60551 18.6108 4.55351C18.6108 3.51051 17.8628 2.62851 16.8318 2.45951C16.4238 2.39251 16.1468 2.00651 16.2138 1.59751C16.2818 1.18851 16.6648 0.914506 17.0758 0.979506C18.8338 1.26851 20.1108 2.77251 20.1108 4.55551C20.1068 6.35051 18.7658 7.89051 16.9908 8.13951C16.9558 8.14451 16.9208 8.14651 16.8868 8.14651Z"
      fill="#808191"
    />
    <g mask="url(#mask0_641_905)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8614 14.5313C19.5594 14.5313 19.2744 14.3473 19.1604 14.0483C19.0124 13.6613 19.2074 13.2273 19.5944 13.0803C20.2724 12.8223 20.2724 12.5373 20.2724 12.4003C20.2724 11.9623 19.7154 11.6563 18.6174 11.4923C18.2084 11.4303 17.9254 11.0493 17.9864 10.6383C18.0474 10.2283 18.4274 9.95528 18.8404 10.0083C21.2634 10.3713 21.7724 11.5093 21.7724 12.4003C21.7724 12.9443 21.5584 13.9373 20.1284 14.4823C20.0404 14.5153 19.9504 14.5313 19.8614 14.5313Z"
        fill="#808191"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8867 11.9141C8.5107 11.9141 5.6777 12.2061 5.6777 13.5961C5.6777 14.9971 8.5107 15.2911 10.8867 15.2911C13.2627 15.2911 16.0947 15.0001 16.0947 13.6131C16.0947 12.2091 13.2627 11.9141 10.8867 11.9141ZM10.8867 16.7911C9.2277 16.7911 4.1777 16.7911 4.1777 13.5961C4.1777 10.4141 9.2277 10.4141 10.8867 10.4141C12.5457 10.4141 17.5947 10.4141 17.5947 13.6131C17.5947 16.7911 12.7217 16.7911 10.8867 16.7911Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8867 1.5C9.1947 1.5 7.8187 2.877 7.8187 4.569C7.8157 5.389 8.1297 6.156 8.7047 6.735C9.2797 7.314 10.0457 7.635 10.8617 7.638L10.8867 8.388V7.638C12.5787 7.638 13.9557 6.262 13.9557 4.569C13.9557 2.877 12.5787 1.5 10.8867 1.5ZM10.8867 9.138H10.8597C9.6397 9.134 8.4967 8.656 7.6407 7.792C6.7827 6.929 6.3137 5.783 6.3187 4.566C6.3187 2.05 8.3677 0 10.8867 0C13.4067 0 15.4557 2.05 15.4557 4.569C15.4557 7.088 13.4067 9.138 10.8867 9.138Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.88569 8.14645C4.85169 8.14645 4.81669 8.14445 4.78169 8.13945C3.00669 7.89045 1.66669 6.35045 1.66269 4.55745C1.66269 2.77245 2.93969 1.26845 4.69769 0.97945C5.11869 0.91345 5.49169 1.19045 5.55969 1.59745C5.62669 2.00645 5.34969 2.39245 4.94169 2.45945C3.91069 2.62845 3.16269 3.51045 3.16269 4.55545C3.16469 5.60545 3.94969 6.50845 4.98869 6.65345C5.39969 6.71045 5.68469 7.08945 5.62769 7.50045C5.57469 7.87545 5.25369 8.14645 4.88569 8.14645Z"
      fill="#808191"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.91209 14.5313C1.82309 14.5313 1.73309 14.5153 1.64509 14.4823C0.214092 13.9363 9.15527e-05 12.9433 9.15527e-05 12.4003C9.15527e-05 11.5103 0.509092 10.3713 2.93309 10.0083C3.34609 9.95631 3.72409 10.2283 3.78609 10.6383C3.84709 11.0493 3.56409 11.4303 3.15509 11.4923C2.05709 11.6563 1.50009 11.9623 1.50009 12.4003C1.50009 12.5373 1.50009 12.8213 2.17909 13.0803C2.56609 13.2273 2.76109 13.6613 2.61309 14.0483C2.49909 14.3473 2.21409 14.5313 1.91209 14.5313Z"
      fill="#808191"
    />
  </svg>
);

export default UserManagment;
