import React from "react";
// import Select from "react-select";
import Select from "react-select/creatable";
import styles from "./Select.module.scss";

const CustomSelect = ({
  options,
  label,
  value,
  isClearable = false,
  onChange,
  onDisableCreate = true,
  onCreateOption,
  ...rest
}) => {
  return (
    <div>
      {label && <div className={styles.label}>{label}</div>}
      <Select
        options={options}
        value={value}
        onChange={onChange}
        onCreateOption={(value) => onCreateOption && onCreateOption(value)}
        isClearable={isClearable}
        isValidNewOption={(input, _, options) => {
          if (typeof onDisableCreate === "boolean") return !onDisableCreate;
          return onDisableCreate(input, options);
        }}
        styles={{
          control: (provided, { isFocused, isDisabled }) => {
            return {
              ...provided,
              padding: 0,
              paddingLeft: "16px",
              height: "50px",
              backgroundColor: isFocused ? "white" : "#f0f0f0",
              borderRadius: "8px",
              boxShadow: "none",
              border: `2px solid ${isFocused ? "#4dbd47" : "#f0f0f0"}`,
              opacity: isDisabled ? "0.5" : "1",
              "&:hover": {
                boxShadow: "none",
              },
            };
          },
          input: (provided) => ({
            ...provided,
            padding: 0,
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          placeholder: (provided) => ({
            ...provided,
            fontSize: "14px",
            color: "#b2b3bd",
          }),
          dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            color: "#11142D",
            transition: "0.2s",
            transform: isFocused ? "rotate(180deg)" : "rotate(0deg)",
          }),
          menu: (provided) => ({
            ...provided,
            boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.32)",
            borderRadius: "8px",
          }),
          option: (provided, state) => {
            return {
              ...provided,
              color: state.isSelected ? "#4DBD47" : "black",
              backgroundColor: "transparent",
              "&:hover": {
                color: "white",
                backgroundColor: "#4DBD47",
              },
            };
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default CustomSelect;
