import React from "react";
import styles from "./Filter.module.scss";

const Filter = () => {
  return (
    <div className={styles.filter}>
      <div className={styles.filter_item_postDate}>Post date</div>
      <div className={styles.filter_item_postTitle}>Post title</div>
      <div className={styles.filter_item_postCategory}>Category</div>
      <div className={styles.filter_item_postSubcategory}>Sub category</div>
      <div style={{ flex: 1 }} />
    </div>
  );
};

export default Filter;
